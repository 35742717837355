import React, { useState } from 'react';
import styled from 'styled-components';

import PageContainer from '../components/PageContainer';

const Header = styled.h1``;

const ChallengePrompt = styled.h3``;

const ChallengeButton = styled.button`
  border: 1px solid gray;
  border-radius: 4px;
  background-color: white;
  margin: 4px;
  width: 200px;
  box-shadow: 2px 2px;
  cursor: pointer;
  @media (max-width: 375px) {
    width: 100%;
  }
`;

const ChallengeButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const RandomRaceButton = styled(ChallengeButton)`
  background: lightgray;
  box-shadow: 2px 2px;
  margin: 20px 0;
`;

const Challenges = (props) => (
  <>
    <RandomRaceButton
      onClick={() =>
        props.onClick(
          props.challengeSet[
            Math.floor(Math.random() * Math.floor(props.challengeSet.length))
          ],
        )
      }
    >
      Start a new race!
    </RandomRaceButton>
    <h4>Or select your favorite race</h4>
    <ChallengeButtonContainer>
      {props.challengeSet.map((challenge) => (
        <ChallengeButton onClick={() => props.onClick(challenge)}>
          {challenge}
        </ChallengeButton>
      ))}
    </ChallengeButtonContainer>
  </>
);

const SuccessMessage = (props) => {
  const { answerTime, recordAnswerTime } = props;

  if (!answerTime) return null;

  return answerTime < recordAnswerTime ? (
    <div style={{ marginTop: '10px' }}>
      Success! {answerTime}ms! Old record: {recordAnswerTime}ms
    </div>
  ) : (
    <div style={{ marginTop: '10px' }}>Done 🎉 {answerTime}ms!</div>
  );
};

const AnswerInput = styled.input`
  height: 50px;
  align-self: stretch;
  padding: 0 8px;
  font-size: 20px;
  border: 1px solid gray;
  border-radius: 4px;
  @media (max-width: 375px) {
    max-width: 370px;
  }
  color: ${(props) => (props.hasError ? 'red' : 'black')};
`;

const TypeRace = ({ data }) => {
  const challengeSet = [
    'To understand what recursion is... You must first understand what recursion is',
    'What do you call a belt made out of watches? A waist of time.',
    'Where do programmers like to hangout? The Foo Bar',
    'Bears, beets, battlestar galactica.',
  ];

  const [answerInput, setAnswerInput] = useState('');
  const [selectedChallenge, setSelectedChallenge] = useState();
  const [hasError, setHasError] = useState(false);
  const [startTime, setStartTime] = useState();
  const [answerTime, setAnswerTime] = useState();
  const [recordAnswerTime, setRecordAnswerTime] = useState();

  const onInputChange = (event) => {
    const newAnswerInput = event.target.value;
    const timeSnapshot = Date.now();
    const matches = selectedChallenge.indexOf(newAnswerInput) === 0;

    setAnswerInput(newAnswerInput);
    setHasError(!matches);
    if (matches && newAnswerInput.length === selectedChallenge.length) {
      setAnswerTime(timeSnapshot - startTime);
    }
  };

  const onChallengeSelect = (challenge) => {
    if (
      (!recordAnswerTime && answerTime > 0) ||
      (answerTime >= 0 && answerTime < recordAnswerTime)
    ) {
      setRecordAnswerTime(answerTime);
    }
    setStartTime(Date.now());
    setAnswerTime(null);
    setSelectedChallenge(challenge);
    setHasError(false);
    setAnswerInput('');
  };

  return (
    <PageContainer>
      <Header>Type Race</Header>
      {selectedChallenge && (
        <>
          <ChallengePrompt>{selectedChallenge}</ChallengePrompt>
          <AnswerInput
            value={answerInput}
            hasError={hasError}
            onChange={onInputChange}
          />
          <SuccessMessage
            answerTime={answerTime}
            recordAnswerTime={recordAnswerTime}
          />
        </>
      )}
      <Challenges challengeSet={challengeSet} onClick={onChallengeSelect} />
    </PageContainer>
  );
};

export default TypeRace;
